import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import MovingElement from "@elements/MovingElement"
import { motion } from "framer-motion"

const Section = styled.section`
  ${tw`
		relative
		z-20
    h-auto
    md:min-h-80vw lg:min-h-70vw 
    flex
    flex-col
  `}
`

const ContentWrap = styled.div`
  ${tw`
		relative
    col-span-12
		z-10
    text-black
    font-ubuntu
    font-black
    mt-auto mb-auto
    pt-72 md:pt-24
    px-offset md:px-offsetMd lg:px-16 xl:px-24 2xl:px-32
    flex
    flex-col
    items-center

    sm:w-3/4 md:w-1/2 2xl:w-3/5
	`}
`
const ImageWrap = styled.div`
  ${tw`
    absolute right-0 top-0 -bottom-40
    flex flex-col
    col-span-12 md:col-span-6
    mt-c20 md:mt-c48 2xl:mt-c60
    md:mb-c96
    sm:w-3/5 mx-auto left-0 sm:left-auto
    max-h-full
  `}
`
const Description = styled.div`
  ${tw`
		relative
		z-10
    pt-6
    pb-20 md:pb-0
    font-normal
    md:col-start-2
    md:text-center
    lg:px-8 xl:px-12
    flex
    flex-col
    items-center
    md:w-3/4 mr-auto
	`}
`
const Bracket = styled.span`
  ${tw`hidden md:block absolute font-firacode md:-top-6 xl:-top-10 right-0 z-0 md:text-400/400 xl:text-500/500 text-yellow font-bold`}
  writing-mode: vertical-lr;
`

const WpAcfHeaderRefBlock = ({ moduleData, featuredImage }) => {
  const blockData = moduleData.acfRefHeaderBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes?.anchor || ""
  const image = getImage(featuredImage.node.localFile)

  return (
    <Section id={sectionId}>
      <ContentWrap>
        <h1 className="headline-refheader font-ubuntu font-bold text-40 xl:text-64 2xl:text-80 relative">
          <span
            className="relative text-right"
            data-start-symbol={blockData.startSymbol}
            data-end-symbol={blockData.endSymbol}
          >
            {parse(blockData.headline)}
          </span>
        </h1>
        <Description className="copy md:mt-8">
          {parse(blockData.description)}

          <div className="floating-symbol pt-c25 relative">
            <MovingElement
              speed={0.05}
              direction={{
                negative: "",
                axis: "y",
              }}
              content={false}
              className="absolute transform top-c40 left-0 right-0 mx-auto transition-all duration-300 ease-linear"
            >
              <span
                style={{
                  writingMode: "vertical-lr",
                  lineHeight: "1.6",
                }}
                className="scroll-element font-firacode whitespace-nowrap text-6xl text-center font-black text-blue absolute transform top-c80 left-0 right-0 mx-auto transition-all duration-300 ease-linear"
              >
                {parse(`->-`)}
              </span>
            </MovingElement>
          </div>
        </Description>
      </ContentWrap>
      <ImageWrap>
        <motion.div className="max-h-full h-full w-full flex justify-center">
          <GatsbyImage
            objectFit=""
            className="w-full mt-24 absolute top-0 left-0 right-0 md:relative shadow-big mx-auto max-w-[250px] max-h-[250px] sm:max-h-full sm:max-w-full"
            image={image}
            alt=""
          />
        </motion.div>
      </ImageWrap>
      <Bracket>{`}`}</Bracket>
    </Section>
  )
}

WpAcfHeaderRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfHeaderRefBlock
